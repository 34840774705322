import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import { withTranslation } from 'react-i18next';
import DateTime from 'react-datetime';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Link } from "react-router-dom";
import momentZone from "moment-timezone";
import ReactTooltip from 'react-tooltip';
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState, ContentState } from 'draft-js';
import { WithContext as ReactTags } from 'react-tag-input';
import Cropper from 'react-easy-crop';
import { SketchPicker } from 'react-color';
import {
    withRouter,
    participantsArray,
    durationArray,
    parseDuration,
    parseParticipant,
    getTextDuration,
    getTextParticipant,
    getQueryVariable,
    defaultColor,
    getRemainingSubscriptionTime,
    timeToDecimal,
    getTenantId
} from '../../utils/HelperFunctions';
import { Defines } from '../../utils/FanoutDefines';
import uploadIcon from '../../resources/icons/upload.svg';
import copyIcon from '../../resources/icons/copy.svg';
import deleteIcon from '../../resources/icons/delete.svg';
import previewIcon from '../../resources/icons/preview.svg';
import defaultBackgroundLobby from '../../resources/images/strmz-background.png';
import MyHosts from './MyHosts';
import Loader from '../Widget/Loader';
import ClickOutside from '../Widget/ClickOutside';
import Preview from '../Widget/Preview';
import firebaseClient from '../../utils/FirebaseClient';
import appClient from '../../utils/AppClient';
import * as firebaseActions from '../../reducers/firebase';
import * as appActions from '../../reducers/app';
import config from '../../config';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';


const KeyCodes = {
    comma: 188,
    enter: 13,
    tab: 9,
    space: 32,
};


export function Stepper(props) {

    return (
        <div className='stepper'>
            <ul>
                <li className={props.activeTab.length >= 0 ? 'active' : ''}></li>
                <li className={props.activeTab.length >= 1 ? 'active' : ''}></li>
                <li className={props.activeTab.length >= 2 ? 'active' : ''}></li>
                <li className={props.activeTab.length >= 3 ? 'active' : ''}></li>
                <li className={props.activeTab.length >= 4 ? 'active' : ''}></li>
                <li className={props.activeTab.length >= 5 ? 'active' : ''}></li>
            </ul>
        </div>
    )
}

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab, KeyCodes.space];

class EventScheduler extends Component {
    constructor(props) {
        super(props);
        this.selector = React.createRef();
        this.state = {
            tab: 1,
            timeZone: momentZone.tz.guess(),
            startDate: this.getDefaultTime(),
            description: null,
            descriptionText: '',
            editorState: EditorState.createEmpty(),
            title: '',
            eventSubtitle: '',
            participants: 500,
            duration: 1,
            processing: false,
            eventId: null,
            eventItem: null,
            logo: null,
            addLogoDisabled: false,
            corporateLogo: null,
            addCorporateLogoDisabled: false,
            croppedAreaPixels: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 16 / 9,
            imgUrl: null,
            cropView: false,
            cropType: null,
            backgroundLobbyAudience: null,
            addBgDisabled: false,
            wideHeaderBanner: null,
            addWideHeaderBannerDisabled: false,
            narrowHeaderBanner: null,
            addNarrowHeaderBannerDisabled: false,
            wideFooterBanner: null,
            addWideFooterBannerDisabled: false,
            narrowFooterBanner: null,
            addNarrowFooterBannerDisabled: false,
            colorSchemeEventPage: null,
            colorSchemeEventTitle: null,
            colorSchemeEventDetails: null,
            colorSchemeEventRegistration: null,
            wideSupplementaryPicture: null,
            addWideSupplementaryPictureDisabled: false,
            narrowSupplementaryPicture: null,
            addNarrowSupplementaryPictureDisabled: false,
            hosts: [],
            hostsSuggestions: [],
            guestSpeakers: [],
            guestSpeakersSuggestions: [],
            moderators: [],
            moderatorsSuggestions: [],
            postId: null,
            showTitle: '',
            audienceAuthenticate: false,
            specifyDomains: false,
            domains: [],
            domainsSuggestions: [],
            cloneEvent: false,
            hostsOverlay: false,
            CPEventPageBg: false,
            CPEventTitleColor: false,
            CPEventTitleBg: false,
            CPEventDetailsColor: false,
            CPEventDetailsBg: false,
            CPEventRegColor: false,
            CPEventRegBg: false,
            CPEventRegBtn: false,
            preview: '',
            resolutionSwitch: true,
            resolution: Defines.Resolution.FullHD,
            videoCodec: Defines.VideoCodec.VP9,
            activeStep: 'description',
            key: 'description',
            activeTab: [],
            showOverage: false,
            defaultLayout: Defines.RecordingLayout.NoCrop,
            labels: Defines.Labels.Default,
            banners: [],
            bannerImg: '',
            bannerLink: '',
            bannerId: '',
        }

        this.addLogoRef = React.createRef();
        this.addCorLogoRef = React.createRef();
        this.addBgRef = React.createRef();
        this.addWideHeaderBannerRef = React.createRef();
        this.addNarrowHeaderBannerRef = React.createRef();
        this.addWideFooterBannerRef = React.createRef();
        this.addNarrowFooterBannerRef = React.createRef();
        this.addWideSupplementaryPictureRef = React.createRef();
        this.addNarrowSupplementaryPictureRef = React.createRef();

        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onContentStateChange = this.onContentStateChange.bind(this);
        this.validDate = this.validDate.bind(this);
        this.validStartDate = this.validStartDate.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.saveCropImage = this.saveCropImage.bind(this);
        this.cancelCrop = this.cancelCrop.bind(this);
        this.showHostOverlay = this.showHostOverlay.bind(this);
        this.closeHostOverlay = this.closeHostOverlay.bind(this);
        this.handleChangeResolution = this.handleChangeResolution.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        const { params, user, navigate, userLoaded, showSignIn } = this.props;

        if (!user && userLoaded && showSignIn) {
            showSignIn(true)
        }

        if (user && user.eventAdmin) {
            if (params && params.eventId) {
                this.getEventData(params.eventId);
            } else {
                this.setUserDetails()
            }
        } else if (user && !user.eventAdmin) {
            navigate("/");
        }

    }

    componentDidUpdate(prevProps) {
        const { params, user, navigate, userLoaded, showSignIn } = this.props;

        if (!user && userLoaded && prevProps.userLoaded !== userLoaded && showSignIn) {
            showSignIn(true)
        }

        if (prevProps.user !== user) {
            if (user && user.eventAdmin) {
                if (params && params.eventId) {
                    this.getEventData(params.eventId);
                } else {
                    this.setUserDetails()
                }
                // this.setResolutionValue();
            } else if ((!user) || (user && !user.eventAdmin)) {
                navigate("/");
            }
        }

    }

    setResolutionValue() {
        const { user } = this.props;

        let isEnabled = false;

        if (user && user.administrator) {
            isEnabled = true;
        }

        if (!isEnabled && user && user.subscription && user.subscription.type && user.subscription.type !== 'explorer') {
            isEnabled = true;
        }

        if (isEnabled) {
            this.setState({
                resolution: Defines.Resolution.FullHD,
                videoCodec: Defines.VideoCodec.VP9,
                resolutionSwitch: true
            })
        }
    }

    setUserDetails() {
        const { user } = this.props;

        if (user && user.subscription) {
            this.setState({
                hosts: user.subscription.hosts
            });
        }
    }

    parseParticipantsArray(array) {
        let returnValue = [];

        if (array && array.length) {
            array.map((item) => {
                if (item.mail) {
                    let val = {
                        id: item.mail,
                        email: item.mail,
                        text: item.mail,
                    }
                    returnValue.push(val);
                } else {
                    returnValue.push(item);
                }
                return item;
            })
        }

        return returnValue;
    }

    parseTime(time, zone, edit = false) {
        let now = Date.now(), start = null;

        if ((time > now) || edit) {
            start = moment(time ? new Date(time) : Date.now());
        } else {
            start = moment(Date.now());
        }

        const remainder = 5 - (start.minute() % 5);
        let dateTime = null;

        if (remainder && remainder < 5) {
            dateTime = moment(start).add(remainder, "minutes").tz(zone ? zone : momentZone.tz.guess());
        } else {
            dateTime = moment(start).tz(zone ? zone : momentZone.tz.guess());
        }

        return dateTime;
    }

    getDefaultTime() {
        const start = moment();

        start.add(1, 'days').set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

        const dateTime = moment(start).tz(momentZone.tz.guess());

        return dateTime;
    }

    getEventData(eventId) {
        const { user, navigate } = this.props;

        if (eventId) {
            firebaseClient.getEvent(eventId).then(async (item) => {
                if (item && item.uid && user && user.uid && user.uid === item.uid) {
                    let description = item.description ? item.description : '';

                    if (description && typeof description !== 'string') {
                        description = convertFromRaw(description);
                        description = EditorState.createWithContent(description);
                    } else {
                        description = EditorState.createWithContent(ContentState.createFromText(description))
                    }

                    let operationId = window.atob(getQueryVariable('o')), cloneEvent = false;

                    if (operationId && operationId === eventId) {
                        cloneEvent = true;
                    }

                    this.setState({
                        showTitle: item.title,
                        postId: !cloneEvent && item.id ? item.id : '',
                        title: item.title,
                        eventSubtitle: item.eventSubtitle,
                        description: item.description,
                        editorState: description,
                        descriptionText: description.getCurrentContent().getPlainText(),
                        logo: item.logo,
                        corporateLogo: item.branding && item.branding.corporateLogo && this.isBranding() ? item.branding.corporateLogo : null,
                        backgroundLobbyAudience: item.branding && item.branding.backgroundLobbyAudience && this.isBranding() ? item.branding.backgroundLobbyAudience : null,
                        wideHeaderBanner: item.branding && item.branding.wideHeaderBanner && this.isBranding() ? item.branding.wideHeaderBanner : null,
                        narrowHeaderBanner: item.branding && item.branding.narrowHeaderBanner && this.isBranding() ? item.branding.narrowHeaderBanner : null,
                        wideFooterBanner: item.branding && item.branding.wideFooterBanner && this.isBranding() ? item.branding.wideFooterBanner : null,
                        narrowFooterBanner: item.branding && item.branding.narrowFooterBanner && this.isBranding() ? item.branding.narrowFooterBanner : null,
                        wideSupplementaryPicture: item.branding && item.branding.wideSupplementaryPicture && this.isBranding() ? item.branding.wideSupplementaryPicture : null,
                        narrowSupplementaryPicture: item.branding && item.branding.narrowSupplementaryPicture && this.isBranding() ? item.branding.narrowSupplementaryPicture : null,
                        colorSchemeEventPage: item.branding && item.branding.colorSchemeEventPage && item.branding.colorSchemeEventPage.bg && this.isBranding() ? item.branding.colorSchemeEventPage : null,
                        colorSchemeEventTitle: item.branding && item.branding.colorSchemeEventTitle && (item.branding.colorSchemeEventTitle.color || item.branding.colorSchemeEventTitle.bg) && this.isBranding() ? item.branding.colorSchemeEventTitle : null,
                        colorSchemeEventDetails: item.branding && item.branding.colorSchemeEventDetails && (item.branding.colorSchemeEventDetails.color || item.branding.colorSchemeEventDetails.bg) && this.isBranding() ? item.branding.colorSchemeEventDetails : null,
                        colorSchemeEventRegistration: item.branding && item.branding.colorSchemeEventRegistration && (item.branding.colorSchemeEventRegistration.color || item.branding.colorSchemeEventRegistration.bg || item.branding.colorSchemeEventRegistration.btn) && this.isBranding() ? item.branding.colorSchemeEventRegistration : null,
                        timeZone: item.timeZone,
                        startDate: cloneEvent ? this.getDefaultTime() : this.parseTime(item.startDate, item.timeZone, !cloneEvent),
                        duration: parseDuration(item.duration),
                        participants: parseParticipant(item.participants),
                        hosts: await this.parseEventHosts(item.hosts) || [],
                        guestSpeakers: item.guestSpeakers || [],
                        moderators: item.moderators || [],
                        audienceAuthenticate: item.audienceAuthenticate || false,
                        specifyDomains: (item.domains && item.domains.length) || false,
                        domains: item.domains || [],
                        cloneEvent: cloneEvent,
                        defaultLayout: item.callParams && item.callParams.layoutParams && item.callParams.layoutParams.defaultLayout ? item.callParams.layoutParams.defaultLayout : item.callParams && item.callParams.layout ? item.callParams.layout : Defines.RecordingLayout.NoCrop,
                        labels: item.callParams && item.callParams.layoutParams && item.callParams.layoutParams.labels ? item.callParams.layoutParams.labels : Defines.Labels.Default,
                        // bannerImg: item.callParams && item.callParams.layoutParams && item.callParams.layoutParams.banners && getDefaultBanner(item.callParams.layoutParams.banners) && getDefaultBanner(item.callParams.layoutParams.banners).img ? getDefaultBanner(item.callParams.layoutParams.banners).img : null,
                        // bannerLink: item.callParams && item.callParams.layoutParams && item.callParams.layoutParams.banners && getDefaultBanner(item.callParams.layoutParams.banners) && getDefaultBanner(item.callParams.layoutParams.banners).link ? getDefaultBanner(item.callParams.layoutParams.banners).link : null,
                    });
                } else {
                    navigate("/");
                }
            });
        }
    }

    isBranding() {
        const { user } = this.props;

        let retVal = false;

        if ((user && user.subscription && user.subscription.event_brending && user.subscription.event_brending === 'event_card_lobby') || (user && !user.subscription)) {
            retVal = true;
        }

        return retVal;
    }

    async parseEventHosts(hosts) {
        const { user } = this.props;

        if (user && user.subscription) {
            let newHosts = [];

            if (hosts && hosts.length) {
                await Promise.all(hosts.map(async (host) => {
                    if (host && (host.email || host.mail) && user.subscription.hosts && user.subscription.hosts.length) {
                        await Promise.all(user.subscription.hosts.map((item) => {
                            if (item && item.mail && ((host.email && item.mail === host.email) || (host.mail && item.mail === host.mail))) {
                                newHosts.push(item);
                            }
                            return item;
                        }));
                    }
                }));
            }

            return newHosts;
        } else if (user && !user.subscription) {
            return hosts;
        } else return [];
    }

    validDate(current) {
        var yesterday = moment().subtract(1, 'day');
        return current.isAfter(yesterday);
    }

    validStartDate(current) {
        const { timeZone } = this.state;

        if (current.isAfter(moment())) {
            return current;
        } else return moment(this.parseTime(moment().toDate().getTime(), timeZone));
    }

    handleChangeStartDate(e) {
        this.setState({
            startDate: this.validStartDate(e)
        });
    }

    onEditorStateChange(e) {
        this.setState({
            editorState: e,
            descriptionText: e.getCurrentContent().getPlainText()
        });
    }

    onContentStateChange(e) {
        this.setState({ description: e });
    }

    handleCopy(event, type, ref) {
        const { user } = this.props;

        let tenantId = getTenantId(user);

        if (event && type) {
            if (ref) {
                ref.disabled = true;
            }
            firebaseClient.getCopyLink(event, type, tenantId).then((link) => {
                if (link) {
                    ReactTooltip.show(ref);
                    navigator.clipboard.writeText(link);
                    if (ref) {
                        ref.disabled = false;
                    }
                    setTimeout(() => {
                        ReactTooltip.hide(ref);
                    }, 2000);
                }
            })
        }
    }

    submitForm() {
        const { title, eventSubtitle, description, descriptionText, participants, duration, audienceAuthenticate,
            startDate, timeZone, logo, hosts, guestSpeakers, moderators, postId, specifyDomains, domains } = this.state;
        const { user } = this.props;

        let event = {
            postId,
            title,
            eventSubtitle,
            description,
            descriptionText,
            participants,
            duration,
            timeZone,
            logo,
            branding: this.getBrandingData(),
            callParams: this.getCallParams(),
            startDate: startDate ? startDate.toDate().getTime() : moment().toDate().getTime(),
            uid: user && user.uid ? user.uid : null,
            author: user && user.username ? user.username : null,
            authorPic: user ?
                user.profile_picture1 ?
                    user.profile_picture1 :
                    user.profile_picture2 ?
                        user.profile_picture2 :
                        user.profile_picture ?
                            user.profile_picture :
                            null
                : null,
            hosts: user.subscription ? hosts : this.parseEmailsToLowerCase(hosts),
            guestSpeakers: this.parseEmailsToLowerCase(guestSpeakers),
            moderators: this.parseEmailsToLowerCase(moderators),
            audienceAuthenticate,
            domains: specifyDomains ? this.parseEmailsToLowerCase(domains, false) : [],
            website: process.env.website
        };

        this.setState({
            processing: true
        }, () => {
            firebaseClient.saveCalendarEvent(event).then((res) => {
                this.setState({
                    processing: false,
                    tab: this.isBranding() ? 6 : 5,
                    eventId: res && res.id ? res.id : null,
                    eventItem: res
                }, () => {
                    this.updateStoreEvents(event)
                });
            });
        });
    }

    getCallParams() {
        const { resolution, videoCodec, defaultLayout, labels, banners } = this.state;

        let data = {
            resolution,
            videoCodec,
            layoutParams: {
                banners,
                // banners: firebaseClient.ifAdminOrAmbassador() ? saveUnsaveBanner([], bannerImg, bannerLink) : this.getRTDefaultBanner(),
                labels,
                defaultLayout,
                layoutSettings: true
            }
        }

        return data;
    }

    getBrandingData() {
        const { logo, corporateLogo, wideHeaderBanner, narrowHeaderBanner, wideFooterBanner, narrowFooterBanner, backgroundLobbyAudience,
            wideSupplementaryPicture, narrowSupplementaryPicture, colorSchemeEventPage, colorSchemeEventTitle, colorSchemeEventDetails, colorSchemeEventRegistration } = this.state;

        let branding = null;

        if (this.isBranding()) {
            branding = {
                logo,
                corporateLogo,
                wideHeaderBanner,
                narrowHeaderBanner,
                wideFooterBanner,
                narrowFooterBanner,
                colorSchemeEventPage,
                colorSchemeEventTitle,
                colorSchemeEventDetails,
                colorSchemeEventRegistration,
                wideSupplementaryPicture,
                narrowSupplementaryPicture,
                backgroundLobbyAudience
            }
        }

        return branding;
    }

    parseEmailsToLowerCase(array, email = true) {
        if (array && array.length) {
            let parseArray = [];

            array.map((item) => {
                let newItem = {
                    id: item.id.toLowerCase(),
                    text: item.text.toLowerCase()
                };

                if (email) {
                    newItem.email = item.email.toLowerCase();
                }

                parseArray.push(newItem);
                return item;
            });

            return parseArray;
        } else return [];
    }

    updateStoreEvents(data) {
        const { events, updateEvents } = this.props;

        if (events && events.length && data && data.postId) {
            let myArray = events, eventData = data, eventId = data.postId;

            if (eventId) {
                try {
                    delete eventData['postId'];
                    eventData['id'] = eventId;
                    eventData['type'] = 'event';

                    myArray = myArray.map(el => (el.id === eventId ? eventData : el));
                } catch (error) {
                    console.error(error);
                }
            }

            if (updateEvents) {
                updateEvents(myArray);
            }
        }
    }

    async handleSetCropImage(event, type) {
        const { user } = this.props;

        if (event.target.files && event.target.files[0] && user) {
            let aspectRation = 16 / 9;
            if (type) {
                switch (type) {
                    case 'logo':
                        this.setState({
                            addLogoDisabled: true
                        });
                        aspectRation = 1;
                        break;
                    case 'corporate-logo':
                        this.setState({
                            addCorporateLogoDisabled: true
                        });
                        aspectRation = 3 / 2;
                        break;
                    case 'background-lobby-audience':
                        this.setState({
                            addBgDisabled: true
                        });
                        aspectRation = 16 / 9;
                        break;
                    case 'wide-header-banner':
                        this.setState({
                            addWideHeaderBannerDisabled: true
                        });
                        aspectRation = 4 / 1;
                        break;
                    case 'narrow-header-banner':
                        this.setState({
                            addNarrowHeaderBannerDisabled: true
                        });
                        aspectRation = 3 / 2;
                        break;
                    case 'wide-footer-banner':
                        this.setState({
                            addWideFooterBannerDisabled: true
                        });
                        aspectRation = 4 / 1;
                        break;
                    case 'narrow-footer-banner':
                        this.setState({
                            addNarrowFooterBannerDisabled: true
                        });
                        aspectRation = 3 / 2;
                        break;
                    case 'wide-supplementary-picture':
                        this.setState({
                            addWideSupplementaryPictureDisabled: true
                        });
                        aspectRation = 16 / 9;
                        break;
                    case 'narrow-supplementary-picture':
                        this.setState({
                            addNarrowSupplementaryPictureDisabled: true
                        });
                        aspectRation = 3 / 2;
                        break;
                    default:
                        break;
                }
            }

            let img = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                this.setState({
                    imgUrl: reader.result,
                    cropView: true,
                    cropType: type,
                    aspect: aspectRation
                });
            }

            if (img) {
                reader.readAsDataURL(img);
            }
        }
    }

    handleClickAddImage(type) {
        if (type) {
            switch (type) {
                case 'logo':
                    if (this.addLogoRef && this.addLogoRef.current && this.addLogoRef.current.click) {
                        this.addLogoRef.current.value = null;
                        this.addLogoRef.current.click();
                    }
                    break;
                case 'corporate-logo':
                    if (this.addCorLogoRef && this.addCorLogoRef.current && this.addCorLogoRef.current.click) {
                        this.addCorLogoRef.current.value = null;
                        this.addCorLogoRef.current.click();
                    }
                    break;
                case 'background-lobby-audience':
                    if (this.addBgRef && this.addBgRef.current && this.addBgRef.current.click) {
                        this.addBgRef.current.value = null;
                        this.addBgRef.current.click();
                    }
                    break;
                case 'wide-header-banner':
                    if (this.addWideHeaderBannerRef && this.addWideHeaderBannerRef.current && this.addWideHeaderBannerRef.current.click) {
                        this.addWideHeaderBannerRef.current.value = null;
                        this.addWideHeaderBannerRef.current.click();
                    }
                    break;
                case 'narrow-header-banner':
                    if (this.addNarrowHeaderBannerRef && this.addNarrowHeaderBannerRef.current && this.addNarrowHeaderBannerRef.current.click) {
                        this.addNarrowHeaderBannerRef.current.value = null;
                        this.addNarrowHeaderBannerRef.current.click();
                    }
                    break;
                case 'wide-footer-banner':
                    if (this.addWideFooterBannerRef && this.addWideFooterBannerRef.current && this.addWideFooterBannerRef.current.click) {
                        this.addWideFooterBannerRef.current.value = null;
                        this.addWideFooterBannerRef.current.click();
                    }
                    break;
                case 'narrow-footer-banner':
                    if (this.addNarrowFooterBannerRef && this.addNarrowFooterBannerRef.current && this.addNarrowFooterBannerRef.current.click) {
                        this.addNarrowFooterBannerRef.current.value = null;
                        this.addNarrowFooterBannerRef.current.click();
                    }
                    break;
                case 'wide-supplementary-picture':
                    if (this.addWideSupplementaryPictureRef && this.addWideSupplementaryPictureRef.current && this.addWideSupplementaryPictureRef.current.click) {
                        this.addWideSupplementaryPictureRef.current.value = null;
                        this.addWideSupplementaryPictureRef.current.click();
                    }
                    break;
                case 'narrow-supplementary-picture':
                    if (this.addNarrowSupplementaryPictureRef && this.addNarrowSupplementaryPictureRef.current && this.addNarrowSupplementaryPictureRef.current.click) {
                        this.addNarrowSupplementaryPictureRef.current.value = null;
                        this.addNarrowSupplementaryPictureRef.current.click();
                    }
                    break;
                default:
                    break;
            }
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleHostsDelete(i) {
        this.setState({ hosts: this.state.hosts.filter((host, index) => index !== i) });
    }

    handleHostsAddition(host) {
        if (host && host.id && host.text && this.validateEmail(host.text)) {
            let newHost = { id: host.id, email: host.text, text: host.text }
            this.setState(state => ({ hosts: [...state.hosts, newHost], hostsSuggestions: [] }));
        }
    }

    handleHostsDrag(host, currPos, newPos) {
        const newHosts = [...this.state.hosts].slice();
        newHosts.splice(currPos, 1);
        newHosts.splice(newPos, 0, host);
        this.setState({ hosts: newHosts });
    }

    handleHostsInputChange(text) {
        if (text && this.validateEmail(text)) {
            let newArray = [];
            newArray.push({ id: text, text: text });

            this.setState({ hostsSuggestions: newArray });
        } else {
            this.setState({ hostsSuggestions: [] });
        }
    }

    handleGuestSpeakersDelete(i) {
        this.setState({ guestSpeakers: this.state.guestSpeakers.filter((guest, index) => index !== i) });
    }

    handleGuestSpeakersAddition(guest) {
        if (guest && guest.id && guest.text && this.validateEmail(guest.text)) {
            let newGuestSpeaker = { id: guest.id, email: guest.text, text: guest.text }
            this.setState(state => ({ guestSpeakers: [...state.guestSpeakers, newGuestSpeaker], guestSpeakersSuggestions: [] }));
        }
    }

    handleGuestSpeakersDrag(guest, currPos, newPos) {
        const newGuestSpeakers = [...this.state.guestSpeakers].slice();
        newGuestSpeakers.splice(currPos, 1);
        newGuestSpeakers.splice(newPos, 0, guest);
        this.setState({ guestSpeakers: newGuestSpeakers });
    }

    handleGuestSpeakersInputChange(text) {
        if (text && this.validateEmail(text)) {
            let newArray = [];
            newArray.push({ id: text, text: text });

            this.setState({ guestSpeakersSuggestions: newArray });
        } else {
            this.setState({ guestSpeakersSuggestions: [] });
        }
    }

    handleModeratorsDelete(i) {
        this.setState({ moderators: this.state.moderators.filter((moderator, index) => index !== i) });
    }

    handleModeratorsAddition(moderator) {
        if (moderator && moderator.id && moderator.text && this.validateEmail(moderator.text)) {
            let newModerator = { id: moderator.id, email: moderator.text, text: moderator.text }
            this.setState(state => ({ moderators: [...state.moderators, newModerator], moderatorsSuggestions: [] }));
        }
    }

    handleModeratorsDrag(moderator, currPos, newPos) {
        const newModerators = [...this.state.moderators].slice();
        newModerators.splice(currPos, 1);
        newModerators.splice(newPos, 0, moderator);
        this.setState({ moderators: newModerators });
    }

    handleModeratorsInputChange(text) {
        if (text && this.validateEmail(text)) {
            let newArray = [];
            newArray.push({ id: text, text: text });

            this.setState({ moderatorsSuggestions: newArray });
        } else {
            this.setState({ moderatorsSuggestions: [] });
        }
    }

    handleDomainsDelete(i) {
        this.setState({ domains: this.state.domains.filter((domain, index) => index !== i) });
    }

    handledomainsAddition(domain) {
        if (domain && domain.id && domain.text) {
            let newDomain = { id: domain.id, text: domain.text }
            this.setState(state => ({ domains: [...state.domains, newDomain], domainsSuggestions: [] }));
        }
    }

    handleDomainsDrag(domain, currPos, newPos) {
        const newDomains = [...this.state.domains].slice();
        newDomains.splice(currPos, 1);
        newDomains.splice(newPos, 0, domain);
        this.setState({ domains: newDomains });
    }

    handleDomainsInputChange(text) {
        if (text) {
            let newArray = [];
            newArray.push({ id: text, text: text });

            this.setState({ domainsSuggestions: newArray });
        } else {
            this.setState({ domainsSuggestions: [] });
        }
    }

    handleChangeCheckbox(event) {
        const { hosts } = this.state;

        let hostsList = [...hosts], checkedHost = JSON.parse(event.target.value);

        if (event.target.checked) {
            hostsList = [...hosts, checkedHost];
        } else {
            hostsList = hostsList.filter((host) => {
                if (host && host.mail && checkedHost && checkedHost.mail && host.mail !== checkedHost.mail) {
                    return host;
                } else {
                    return null;
                }
            });
        }

        this.setState({ hosts: hostsList })
    }

    checkAcceptCharge() {
        const { duration, participants, showOverage } = this.state;
        const { user } = this.props;

        let retVal = false;

        if (user && user.subscription && showOverage) {
            if (duration && duration > this.getHoursBalance()) {
                retVal = true;
            } else if (participants && user.subscription.audience_size && participants > user.subscription.audience_size) {
                retVal = true;
            }
        }

        return retVal;
    }

    getHoursBalance() {
        const { user } = this.props;

        let retVal = 0;

        if (user && user.subscription) {
            retVal = timeToDecimal(getRemainingSubscriptionTime(user.subscription) || 0);
        }

        return retVal;
    }

    createImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', (error) => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        })
    }

    getRadianAngle(rotation) {
        return (rotation * Math.PI) / 180
    }

    rotateSize(width, height, rotation) {
        const rotRad = this.getRadianAngle(rotation)

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
        }
    }

    async getCroppedImg(imageSrc, pixelCrop, rotation = 0, flip = { horizontal: false, vertical: false }) {
        const image = await this.createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return null
        }

        const rotRad = this.getRadianAngle(rotation)

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(
            image.width,
            image.height,
            rotation
        )

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth
        canvas.height = bBoxHeight

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
        ctx.rotate(rotRad)
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
        ctx.translate(-image.width / 2, -image.height / 2)

        // draw rotated image
        ctx.drawImage(image, 0, 0)

        // croppedAreaPixels values are bounding box relative
        // extract the cropped image using these values
        const data = ctx.getImageData(
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height
        )

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        // paste generated rotate image at the top left corner
        ctx.putImageData(data, 0, 0)

        // As Base64 string
        // return canvas.toDataURL('image/jpeg');

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob((file) => {
                resolve(file)
            }, 'image/jpeg')
        })
    }

    saveCropImage() {
        const { imgUrl, croppedAreaPixels, cropType } = this.state;
        const { user } = this.props;

        this.setState({
            cropView: false
        }, async () => {
            if (user && user.uid) {
                let id = `${user.uid}/${uuidv4()}`;
                const croppedImage = await this.getCroppedImg(imgUrl, croppedAreaPixels);

                firebaseClient.addImageToStorage(croppedImage, 'event', id).then((res) => {
                    let newState = {
                        croppedAreaPixels: null,
                        crop: { x: 0, y: 0 },
                        zoom: 1,
                        aspect: 16 / 9,
                        imgUrl: null,
                        cropType: null
                    }

                    if (cropType) {
                        switch (cropType) {
                            case 'logo':
                                newState.logo = res;
                                newState.addLogoDisabled = false;
                                break;
                            case 'corporate-logo':
                                newState.corporateLogo = res;
                                newState.addCorporateLogoDisabled = false;
                                break;
                            case 'background-lobby-audience':
                                newState.backgroundLobbyAudience = res;
                                newState.addBgDisabled = false;
                                break;
                            case 'wide-header-banner':
                                newState.wideHeaderBanner = res;
                                newState.addWideHeaderBannerDisabled = false;
                                break;
                            case 'narrow-header-banner':
                                newState.narrowHeaderBanner = res;
                                newState.addNarrowHeaderBannerDisabled = false;
                                break;
                            case 'wide-footer-banner':
                                newState.wideFooterBanner = res;
                                newState.addWideFooterBannerDisabled = false;
                                break;
                            case 'narrow-footer-banner':
                                newState.narrowFooterBanner = res;
                                newState.addNarrowFooterBannerDisabled = false;
                                break;
                            case 'wide-supplementary-picture':
                                newState.wideSupplementaryPicture = res;
                                newState.addWideSupplementaryPictureDisabled = false;
                                break;
                            case 'narrow-supplementary-picture':
                                newState.narrowSupplementaryPicture = res;
                                newState.addNarrowSupplementaryPictureDisabled = false;
                                break;
                            default:
                                break;
                        }
                    }

                    this.setState(newState);
                });
            }
        });
    }

    cancelCrop() {
        this.setState({
            croppedAreaPixels: null,
            crop: { x: 0, y: 0 },
            zoom: 1,
            aspect: 16 / 9,
            imgUrl: null,
            cropView: false,
            cropType: null,
            addLogoDisabled: false,
            addCorporateLogoDisabled: false,
            addBgDisabled: false,
            addWideHeaderBannerDisabled: false,
            addNarrowHeaderBannerDisabled: false,
            addWideFooterBannerDisabled: false,
            addNarrowFooterBannerDisabled: false,
            addWideSupplementaryPictureDisabled: false,
            addNarrowSupplementaryPictureDisabled: false
        });
    }

    showHostOverlay() {
        this.setState({
            hostsOverlay: true
        }, () => {
            appClient.disableScroll(true);
        });
    }

    closeHostOverlay() {
        this.setState({
            hostsOverlay: false
        }, () => {
            appClient.disableScroll(false);
        });
    }

    handleChangeResolution(event) {
        if (event && event.target && event.target.checked) {
            this.setState({
                resolutionSwitch: true,
                resolution: Defines.Resolution.FullHD,
                videoCodec: Defines.VideoCodec.VP9
            });
        } else {
            this.setState({
                resolutionSwitch: false,
                resolution: Defines.Resolution.HDReady,
                videoCodec: Defines.VideoCodec.VP8
            });
        }
    }

    handleSelect(activeStep) {
        this.setState({ activeStep });
    }



    render() {
        const { activeTab, activeStep, tab, timeZone, startDate, editorState, title, eventSubtitle, participants, duration, processing, eventId, eventItem, addLogoDisabled, logo, addCorporateLogoDisabled, corporateLogo, addBgDisabled, backgroundLobbyAudience, audienceAuthenticate, hostsOverlay,
            hosts, guestSpeakers, moderators, hostsSuggestions, guestSpeakersSuggestions, moderatorsSuggestions, postId, showTitle, cloneEvent, specifyDomains, domains, domainsSuggestions, aspect, crop, imgUrl, zoom, cropView,
            addWideHeaderBannerDisabled, wideHeaderBanner, addNarrowHeaderBannerDisabled, narrowHeaderBanner, addWideFooterBannerDisabled, wideFooterBanner, addNarrowFooterBannerDisabled, narrowFooterBanner,
            colorSchemeEventPage, colorSchemeEventTitle, colorSchemeEventDetails, colorSchemeEventRegistration, wideSupplementaryPicture, addWideSupplementaryPictureDisabled, narrowSupplementaryPicture, addNarrowSupplementaryPictureDisabled,
            CPEventPageBg, CPEventTitleColor, CPEventTitleBg, CPEventDetailsColor, CPEventDetailsBg, CPEventRegColor, CPEventRegBg, CPEventRegBtn, preview, resolutionSwitch, showOverage } = this.state;
        const { t, user } = this.props;
        return (
            <div>
                {user && user.eventAdmin ?
                    <div className='event-scheduler'>
                        <div className='event-scheduler-items'>
                            <p className='event-scheduler-title'>
                                {t(postId ? 'update_event' : cloneEvent ? 'clone' : 'create_event')}{showTitle ? ` - ${showTitle}` : ''}
                                {user && user.subscription && user.subscription.type ?
                                    <a
                                        href={
                                            user.subscription.type === 'pro' ?
                                                `https://${config.homeSiteDomain}/pro-create-event`
                                                :
                                                user.subscription.type === 'enterprise' ?
                                                    `https://${config.homeSiteDomain}/enterprise-create-event`
                                                    :
                                                    `https://${config.homeSiteDomain}/explorer-create-event`
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className='help-link'
                                    >
                                        ?
                                    </a>
                                    : null}
                            </p>
                            {/* Tabs component */}
                            <Stepper activeTab={activeTab} />
                            <Tabs
                                defaultActiveKey="description"
                                id="uncontrolled-tab-example"
                                activeKey={activeStep}
                                onSelect={this.handleSelect}
                                className={this.isBranding() ? "nav-tabs--branding-on" : ""}
                            >
                                <Tab id="description" className="btn-arrow" eventKey="description" title="Description" disabled={activeTab.length >= 0 && !activeTab.includes('summary') === true ? false : true}>
                                    <div className='scheduler-item'>
                                        <div className='item-body'>
                                            <div className='section-flex'>
                                                <div className='section-left'>
                                                    <div className='input-wrapper'>
                                                        <p className='wrapp-p'>{t('event_title')}<span className='asterisk'>*</span></p>
                                                        <input
                                                            type="text"
                                                            placeholder={t('enter_title')}
                                                            disabled={processing}
                                                            maxLength={50}
                                                            onChange={(e) => this.setState({ title: e.target.value })}
                                                            value={title}
                                                        />
                                                        {title && title.length && title.length >= 50 ? <p className='host notify-text'>{t('max_characters', { characters: 50 })}</p> : null}
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <p className='wrapp-p'>{t('event_subtitle')}</p>
                                                        <textarea
                                                            placeholder={t('enter_subtitle')}
                                                            rows={4}
                                                            disabled={processing}
                                                            maxLength={150}
                                                            onChange={(e) => this.setState({ eventSubtitle: e.target.value })}
                                                            value={eventSubtitle}
                                                        />
                                                        {eventSubtitle && eventSubtitle.length && eventSubtitle.length >= 150 ? <p className='host notify-text'>{t('max_characters', { characters: 150 })}</p> : null}
                                                    </div>
                                                    <div className='select-dual-wrapper'>
                                                        {/* <p className='wrapp-p'>{t('logo')}</p> */}
                                                        <div
                                                            onClick={() => this.handleClickAddImage('logo')}
                                                            className={classNames('select-add-text', { 'disabled': addLogoDisabled || processing })}
                                                        >
                                                            {logo ?
                                                                <span className='branding-line'>
                                                                    <span
                                                                        className='branding-img'
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            this.setState({
                                                                                logo: null
                                                                            })
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={logo}
                                                                            className='event-logo'
                                                                            alt='img'
                                                                            loading='lazy'
                                                                        />
                                                                        <span className='rm-image'>
                                                                            <img src={deleteIcon} loading='lazy' alt='X' />
                                                                        </span>
                                                                    </span>
                                                                    <p>{t('replace_logo')} <span className='size'>{t('size_3')}</span></p>
                                                                </span>
                                                                :
                                                                <span className='branding-line'>
                                                                    <span className='upload-img'>
                                                                        <img src={uploadIcon} alt='download' loading='lazy' />
                                                                        <p>{t('logo_upload')} <span className='size'>{t('size_3')}</span></p>
                                                                    </span>
                                                                </span>
                                                            }
                                                            <input
                                                                type="file"
                                                                className='add-file'
                                                                ref={this.addLogoRef}
                                                                accept="image/png,image/jpeg,image/svg+xml"
                                                                onChange={(event) => this.handleSetCropImage(event, 'logo')}
                                                            />
                                                        </div>
                                                        <p className='no-wrapp font-sm'>{t('in_event_logo_text')}</p>
                                                    </div>
                                                </div>
                                                <div className='section-right'>
                                                    <div className='input-wrapper'>
                                                        <p className='wrapp-p'>{t('event_description')}</p>
                                                        <Editor
                                                            toolbar={{
                                                                options: ['inline', 'list', 'emoji', 'history'],
                                                                inline: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['bold', 'italic', 'underline']
                                                                },
                                                                list: {
                                                                    inDropdown: false,
                                                                    className: undefined,
                                                                    component: undefined,
                                                                    dropdownClassName: undefined,
                                                                    options: ['unordered', 'ordered']
                                                                }
                                                            }}
                                                            editorState={editorState}
                                                            onEditorStateChange={this.onEditorStateChange}
                                                            onContentStateChange={this.onContentStateChange}
                                                            stripPastedStyles
                                                            readOnly={processing}
                                                            placeholder={t('enter_description')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='buttons'>
                                                <button
                                                    className='btn btn-save'
                                                    onClick={() => {
                                                        this.setState({ tab: 2 })
                                                        this.setState({ activeStep: 'participants' })
                                                        this.setState({ showTitle: title });
                                                        if (!activeTab.includes('description')) { this.setState({ activeTab: [...this.state.activeTab, 'description'] }) }
                                                    }}
                                                    disabled={!title || (title && title.length <= 3) || processing}
                                                >
                                                    {t('next')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab className="btn-arrow" eventKey="participants" title="Participants" disabled={activeTab.length >= 1 && !activeTab.includes('summary') ? false : true}>
                                    <div className='scheduler-item'>
                                        <div className='item-body'>
                                            <div className='section-flex'>
                                                <div className='section-left'>
                                                    <div className='select-dual-wrapper'>
                                                        <div className='select'>
                                                            <p className='wrapp-p'>{t('audience_size')}</p>
                                                            <select
                                                                onChange={(e) => this.setState({ participants: parseInt(e.target.value) })}
                                                                value={participants}
                                                                disabled={processing}
                                                            >
                                                                {participantsArray.map((item, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            style={{ background: '#27272e' }}
                                                                            value={item.value}
                                                                        >
                                                                            {item.text}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {showOverage && user.subscription ? <p className="no-wrapp">{`${t('the_audience_size_included')}: ${user.subscription.audience_size ? user.subscription.audience_size : 500}`}</p> : null}
                                                        </div>
                                                        <div className='select'>
                                                            <p className='wrapp-p'>{t('duration_hours')}</p>
                                                            <select
                                                                onChange={(e) => this.setState({ duration: parseInt(e.target.value) })}
                                                                value={duration}
                                                                disabled={processing}
                                                            >
                                                                {durationArray.map((item, index) => {
                                                                    return (
                                                                        <option
                                                                            key={index}
                                                                            style={{ background: '#27272e' }}
                                                                            value={item.value}
                                                                        >
                                                                            {`${item.text} ${t(item.value && item.value === 1 ? 'hour' : 'hours')}`}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {showOverage && user.subscription && user.subscription.type && user.subscription.type !== 'enterprise' ? <p className="no-wrapp">{`${t('current_hour_balance')}: ${getRemainingSubscriptionTime(user.subscription)}`}</p> : null}
                                                        </div>
                                                    </div>
                                                    {user.subscription ?
                                                        <div className='input-wrapper'>
                                                            <p className='wrapp-p'>{t('host_s')}<span className='asterisk'>*</span></p>
                                                            {user.subscription.hosts && user.subscription.hosts.length ?
                                                                user.subscription.hosts.map((host, index) => {
                                                                    return (
                                                                        <div className='host-input' key={index}>
                                                                            <input
                                                                                type="checkbox"
                                                                                value={JSON.stringify(host)}
                                                                                onChange={this.handleChangeCheckbox}
                                                                                checked={hosts.some(item => item.mail === host.mail)}
                                                                            />
                                                                            {host.username || host.mail || host.email}
                                                                        </div>
                                                                    );
                                                                })
                                                                :
                                                                <p className='host opacity-text notify-text'>{t('no_hosts_configured')}</p>
                                                            }
                                                            <p className="no-wrapp">
                                                                <span
                                                                    onClick={this.showHostOverlay}
                                                                >
                                                                    {t('manage_my_hosts')}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        :
                                                        <div className='input-wrapper'>
                                                            <p className='wrapp-p'>{t('add_host')}<span className='asterisk'>*</span></p>
                                                            <ReactTags
                                                                inputProps={{
                                                                    disabled: processing || (user.subscription && user.subscription.number_of_hosts ? hosts && hosts.length >= user.subscription.number_of_hosts ? true : false : hosts && hosts.length && hosts.length >= 2 ? true : false),
                                                                    type: 'email',
                                                                    autoComplete: "nope",
                                                                    id: 'host-email'
                                                                }}
                                                                inputFieldPosition="top"
                                                                placeholder={t('enter_email')}
                                                                tags={hosts}
                                                                suggestions={hostsSuggestions}
                                                                autofocus={false}
                                                                handleDelete={this.handleHostsDelete.bind(this)}
                                                                handleAddition={this.handleHostsAddition.bind(this)}
                                                                handleDrag={this.handleHostsDrag.bind(this)}
                                                                handleInputChange={this.handleHostsInputChange.bind(this)}
                                                                delimiters={delimiters}
                                                                allowUnique={true}
                                                                allowAdditionFromPaste={false}
                                                                allowDeleteFromEmptyInput={false}
                                                            />
                                                        </div>
                                                    }
                                                    <div className='input-wrapper'>
                                                        <p className='wrapp-p'>{t('security_settings')}</p>
                                                    </div>
                                                    <p className='no-wrapp font-sm no-italic'>{t('security_settings_text')}</p>
                                                    <div className='switch-wrapper'>
                                                        <label className='switch'>
                                                            <input
                                                                type='checkbox'
                                                                checked={audienceAuthenticate}
                                                                onChange={(e) => this.setState({ audienceAuthenticate: e.target.checked })}
                                                                disabled={processing}
                                                            />
                                                            <span className='slider' />
                                                        </label>
                                                        <p>{t('require_audience_authenticate')}</p>
                                                    </div>
                                                    <div className='switch-wrapper'>
                                                        <label className='switch'>
                                                            <input
                                                                type='checkbox'
                                                                checked={specifyDomains}
                                                                onChange={(e) => this.setState({ specifyDomains: e.target.checked })}
                                                                disabled={processing}
                                                            />
                                                            <span className='slider' />
                                                        </label>
                                                        <p>{t('only_domains')}</p>
                                                    </div>
                                                    {specifyDomains ?
                                                        <div className='input-wrapper'>
                                                            <p className='wrapp-p'>{t('add_domain')}</p>
                                                            <ReactTags
                                                                inputProps={{
                                                                    disabled: processing,
                                                                    type: 'text',
                                                                    autoComplete: "nope",
                                                                    id: 'specify-domains'
                                                                }}
                                                                inputFieldPosition="top"
                                                                placeholder={t('enter_domain')}
                                                                tags={domains}
                                                                suggestions={domainsSuggestions}
                                                                autofocus={false}
                                                                handleDelete={this.handleDomainsDelete.bind(this)}
                                                                handleAddition={this.handledomainsAddition.bind(this)}
                                                                handleDrag={this.handleDomainsDrag.bind(this)}
                                                                handleInputChange={this.handleDomainsInputChange.bind(this)}
                                                                delimiters={delimiters}
                                                                allowUnique={true}
                                                                allowAdditionFromPaste={false}
                                                                allowDeleteFromEmptyInput={false}
                                                            />
                                                            <p className='notify-text'>{t('specify_text')}</p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className='section-right'>
                                                    <div className='input-wrapper'>
                                                        <p className='wrapp-p'>{t('add_guest_speaker')}</p>
                                                        <ReactTags
                                                            inputProps={{
                                                                disabled: processing,
                                                                type: 'email',
                                                                autoComplete: "nope",
                                                                id: 'guest-email'
                                                            }}
                                                            inputFieldPosition="top"
                                                            placeholder={t('enter_email')}
                                                            tags={guestSpeakers}
                                                            suggestions={guestSpeakersSuggestions}
                                                            autofocus={false}
                                                            handleDelete={this.handleGuestSpeakersDelete.bind(this)}
                                                            handleAddition={this.handleGuestSpeakersAddition.bind(this)}
                                                            handleDrag={this.handleGuestSpeakersDrag.bind(this)}
                                                            handleInputChange={this.handleGuestSpeakersInputChange.bind(this)}
                                                            delimiters={delimiters}
                                                            allowUnique={true}
                                                            allowAdditionFromPaste={false}
                                                            allowDeleteFromEmptyInput={false}
                                                        />
                                                    </div>
                                                    <div className='input-wrapper'>
                                                        <p className='wrapp-p'>{t('add_moderator')}</p>
                                                        <ReactTags
                                                            inputProps={{
                                                                disabled: processing,
                                                                type: 'email',
                                                                autoComplete: "nope",
                                                                id: 'moderator-email'
                                                            }}
                                                            inputFieldPosition="top"
                                                            placeholder={t('enter_email')}
                                                            tags={moderators}
                                                            suggestions={moderatorsSuggestions}
                                                            autofocus={false}
                                                            handleDelete={this.handleModeratorsDelete.bind(this)}
                                                            handleAddition={this.handleModeratorsAddition.bind(this)}
                                                            handleDrag={this.handleModeratorsDrag.bind(this)}
                                                            handleInputChange={this.handleModeratorsInputChange.bind(this)}
                                                            delimiters={delimiters}
                                                            allowUnique={true}
                                                            allowAdditionFromPaste={false}
                                                            allowDeleteFromEmptyInput={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='buttons'>
                                                <button
                                                    className='btn btn-save'
                                                    onClick={() => {
                                                        this.setState({ tab: 3 })
                                                        this.isBranding() ? this.setState({ activeStep: 'branding' }) : this.setState({ activeStep: 'time_and_date' })
                                                        if (!activeTab.includes('participants')) {
                                                            this.setState({ activeTab: [...this.state.activeTab, 'participants'] })
                                                        }
                                                    }}
                                                    disabled={processing || !hosts || (hosts && !hosts.length)}
                                                >
                                                    {t('next')}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </Tab>
                                {this.isBranding() ?
                                    <Tab className="btn-arrow" eventKey="branding" title="Branding" disabled={activeTab.length >= 2 && !activeTab.includes('summary') === true ? false : true} >
                                        <div className='scheduler-item'>
                                            <div className='item-body'>
                                                <div className='select-color-wrapper'>
                                                    {/* <p className='wrapp-p title'>{t('erpd')}</p> */}
                                                    <div className='branding-template__text-wrapper branding-template__title-box'>
                                                        <span>{t('cs_event_registration_page')}</span>
                                                        <div className={classNames('select-color', { 'disabled': processing })} >
                                                            <div
                                                                className='color-line'
                                                                onClick={() => this.setState({ CPEventPageBg: true })}
                                                                style={colorSchemeEventPage && colorSchemeEventPage.bg ? { background: `rgba(${colorSchemeEventPage.bg.r}, ${colorSchemeEventPage.bg.g}, ${colorSchemeEventPage.bg.b}, ${colorSchemeEventPage.bg.a})` } : { background: defaultColor.eventPage.bg }}
                                                            />
                                                            <p>{t('bg_color')}</p>
                                                            {CPEventPageBg ?
                                                                <div className='color-picker-line'>
                                                                    <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventPageBg: false })} />
                                                                    <SketchPicker
                                                                        color={colorSchemeEventPage && colorSchemeEventPage.bg ? colorSchemeEventPage.bg : defaultColor.eventPage.bg}
                                                                        onChange={(color) => {
                                                                            this.setState(prevState => ({
                                                                                colorSchemeEventPage: {
                                                                                    ...prevState.colorSchemeEventPage,
                                                                                    bg: color.rgb
                                                                                }
                                                                            }));
                                                                        }}
                                                                    />
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div className='branding-template__h-box'>
                                                            <button className="btn-icon" onClick={() => this.setState({ preview: 'event-page' })}>
                                                                <img src={previewIcon} alt='preview' loading='lazy' width="24px" height="24px" />
                                                            </button>
                                                            <span>{t('preview_erp')}</span>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div className="branding-template">
                                                    <div className="branding-template--header">
                                                        <span>STRMZ.IO Header</span>
                                                    </div>
                                                    <div className="branding-template--section branding-template--fill">
                                                        <div className='select-dual-wrapper box-content'>
                                                            <p className='wrapp-p'>{t('header_banner')}</p>
                                                            <div
                                                                onClick={() => this.handleClickAddImage('wide-header-banner')}
                                                                className={classNames('select-add-text', { 'disabled': addWideHeaderBannerDisabled || processing })}
                                                            >
                                                                {wideHeaderBanner ?
                                                                    <span className='branding-line'>
                                                                        <span
                                                                            className='branding-img'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({
                                                                                    wideHeaderBanner: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={wideHeaderBanner}
                                                                                className='small-event-bg'
                                                                                alt='img'
                                                                                loading='lazy'
                                                                            />
                                                                            <span className='rm-image'>
                                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                                            </span>
                                                                        </span>
                                                                        <p>{t('replace_wide_header_banner')} <span className='size'>{t('size_1')}</span></p>
                                                                    </span>
                                                                    :
                                                                    <span className='branding-line'>
                                                                        <span className='upload-img'>
                                                                            <img src={uploadIcon} alt='download' loading='lazy' />
                                                                            <p>{t('wide_header_banner_upload')} <span className='size'>{t('size_1')}</span></p>
                                                                        </span>
                                                                    </span>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='add-file'
                                                                    ref={this.addWideHeaderBannerRef}
                                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                                    onChange={(event) => this.handleSetCropImage(event, 'wide-header-banner')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='select-dual-wrapper  box-content'>
                                                            <div
                                                                onClick={() => this.handleClickAddImage('narrow-header-banner')}
                                                                className={classNames('select-add-text', { 'disabled': addNarrowHeaderBannerDisabled || processing })}
                                                            >
                                                                {narrowHeaderBanner ?
                                                                    <span className='branding-line'>
                                                                        <span
                                                                            className='branding-img'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({
                                                                                    narrowHeaderBanner: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={narrowHeaderBanner}
                                                                                className='event-narrow'
                                                                                alt='img'
                                                                                loading='lazy'
                                                                            />
                                                                            <span className='rm-image'>
                                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                                            </span>
                                                                        </span>
                                                                        <p>{t('replace_narrow_header_banner')} <span className='size'>{t('size_2')}</span></p>
                                                                    </span>
                                                                    :
                                                                    <span className='branding-line'>
                                                                        <span className='upload-img'>
                                                                            <img src={uploadIcon} alt='download' loading='lazy' />
                                                                            <p>{t('narrow_header_banner_upload')} <span className='size'>{t('size_2')}</span></p>
                                                                        </span>
                                                                    </span>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='add-file'
                                                                    ref={this.addNarrowHeaderBannerRef}
                                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                                    onChange={(event) => this.handleSetCropImage(event, 'narrow-header-banner')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="branding-template--section">
                                                        <div className="branding-template--part">
                                                            <div className='select-dual-wrapper  box-content'>
                                                                <p className='wrapp-p'>{t('cor_logo')}</p>
                                                                <div
                                                                    onClick={() => this.handleClickAddImage('corporate-logo')}
                                                                    className={classNames('select-add-text', { 'disabled': addCorporateLogoDisabled || processing })}
                                                                >
                                                                    {corporateLogo ?
                                                                        <span className='branding-line'>
                                                                            <span
                                                                                className='branding-img'
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState({
                                                                                        corporateLogo: null
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={corporateLogo}
                                                                                    className='event-narrow'
                                                                                    alt='img'
                                                                                    loading='lazy'
                                                                                />
                                                                                <span className='rm-image'>
                                                                                    <img src={deleteIcon} loading='lazy' alt='X' />
                                                                                </span>
                                                                            </span>
                                                                            <p>{t('replace_corporate_logo')} <span className='size'>{t('size_2')}</span></p>
                                                                        </span>
                                                                        :
                                                                        <span className='branding-line'>
                                                                            <span className='upload-img'>
                                                                                <img src={uploadIcon} alt='download' loading='lazy' />
                                                                                <p>{t('corporate_logo_upload')} <span className='size'>{t('size_2')}</span></p>
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                    <input
                                                                        type="file"
                                                                        className='add-file'
                                                                        ref={this.addCorLogoRef}
                                                                        accept="image/png,image/jpeg,image/svg+xml"
                                                                        onChange={(event) => this.handleSetCropImage(event, 'corporate-logo')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="branding-template--part">
                                                            <div className='select-color-wrapper  box-content'>
                                                                <p className='wrapp-p'>{t('cs_event_title')}</p>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventTitleColor: true })}
                                                                        style={colorSchemeEventTitle && colorSchemeEventTitle.color ? { background: `rgba(${colorSchemeEventTitle.color.r}, ${colorSchemeEventTitle.color.g}, ${colorSchemeEventTitle.color.b}, ${colorSchemeEventTitle.color.a})` } : { background: defaultColor.eventTitle.color }}
                                                                    />
                                                                    <p>{t('text_color')}</p>
                                                                    {CPEventTitleColor ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventTitleColor: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventTitle && colorSchemeEventTitle.color ? colorSchemeEventTitle.color : defaultColor.eventTitle.color}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventTitle: {
                                                                                            ...prevState.colorSchemeEventTitle,
                                                                                            color: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventTitleBg: true })}
                                                                        style={colorSchemeEventTitle && colorSchemeEventTitle.bg ? { background: `rgba(${colorSchemeEventTitle.bg.r}, ${colorSchemeEventTitle.bg.g}, ${colorSchemeEventTitle.bg.b}, ${colorSchemeEventTitle.bg.a})` } : { background: defaultColor.eventTitle.bg }}
                                                                    />
                                                                    <p>{t('bg_color')}</p>
                                                                    {CPEventTitleBg ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventTitleBg: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventTitle && colorSchemeEventTitle.bg ? colorSchemeEventTitle.bg : defaultColor.eventTitle.bg}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventTitle: {
                                                                                            ...prevState.colorSchemeEventTitle,
                                                                                            bg: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="branding-template--section">
                                                        <div className="branding-template--part">
                                                            <div className='select-color-wrapper  box-content'>
                                                                <p className='wrapp-p'>{t('cs_event_details')}</p>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventDetailsColor: true })}
                                                                        style={colorSchemeEventDetails && colorSchemeEventDetails.color ? { background: `rgba(${colorSchemeEventDetails.color.r}, ${colorSchemeEventDetails.color.g}, ${colorSchemeEventDetails.color.b}, ${colorSchemeEventDetails.color.a})` } : { background: defaultColor.eventDetails.color }}
                                                                    />
                                                                    <p>{t('text_color')}</p>
                                                                    {CPEventDetailsColor ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventDetailsColor: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventDetails && colorSchemeEventDetails.color ? colorSchemeEventDetails.color : defaultColor.eventDetails.color}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventDetails: {
                                                                                            ...prevState.colorSchemeEventDetails,
                                                                                            color: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventDetailsBg: true })}
                                                                        style={colorSchemeEventDetails && colorSchemeEventDetails.bg ? { background: `rgba(${colorSchemeEventDetails.bg.r}, ${colorSchemeEventDetails.bg.g}, ${colorSchemeEventDetails.bg.b}, ${colorSchemeEventDetails.bg.a})` } : { background: defaultColor.eventDetails.bg }}
                                                                    />
                                                                    <p>{t('bg_color')}</p>
                                                                    {CPEventDetailsBg ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventDetailsBg: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventDetails && colorSchemeEventDetails.bg ? colorSchemeEventDetails.bg : defaultColor.eventDetails.bg}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventDetails: {
                                                                                            ...prevState.colorSchemeEventDetails,
                                                                                            bg: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="branding-template--part">
                                                            <div className='select-color-wrapper  box-content'>
                                                                <p className='wrapp-p'>{t('registration_panel')}</p>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventRegColor: true })}
                                                                        style={colorSchemeEventRegistration && colorSchemeEventRegistration.color ? { background: `rgba(${colorSchemeEventRegistration.color.r}, ${colorSchemeEventRegistration.color.g}, ${colorSchemeEventRegistration.color.b}, ${colorSchemeEventRegistration.color.a})` } : { background: defaultColor.eventRegistration.color }}
                                                                    />
                                                                    <p>{t('text_color')}</p>
                                                                    {CPEventRegColor ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventRegColor: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventRegistration && colorSchemeEventRegistration.color ? colorSchemeEventRegistration.color : defaultColor.eventRegistration.color}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventRegistration: {
                                                                                            ...prevState.colorSchemeEventRegistration,
                                                                                            color: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventRegBg: true })}
                                                                        style={colorSchemeEventRegistration && colorSchemeEventRegistration.bg ? { background: `rgba(${colorSchemeEventRegistration.bg.r}, ${colorSchemeEventRegistration.bg.g}, ${colorSchemeEventRegistration.bg.b}, ${colorSchemeEventRegistration.bg.a})` } : { background: defaultColor.eventRegistration.bg }}
                                                                    />
                                                                    <p>{t('bg_color')}</p>
                                                                    {CPEventRegBg ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventRegBg: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventRegistration && colorSchemeEventRegistration.bg ? colorSchemeEventRegistration.bg : defaultColor.eventRegistration.bg}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventRegistration: {
                                                                                            ...prevState.colorSchemeEventRegistration,
                                                                                            bg: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                <div className={classNames('select-color', { 'disabled': processing })} >
                                                                    <div
                                                                        className='color-line'
                                                                        onClick={() => this.setState({ CPEventRegBtn: true })}
                                                                        style={colorSchemeEventRegistration && colorSchemeEventRegistration.btn ? { background: `rgba(${colorSchemeEventRegistration.btn.r}, ${colorSchemeEventRegistration.btn.g}, ${colorSchemeEventRegistration.btn.b}, ${colorSchemeEventRegistration.btn.a})` } : { background: defaultColor.eventRegistration.btn }}
                                                                    />
                                                                    <p>{t('btn_color')}</p>
                                                                    {CPEventRegBtn ?
                                                                        <div className='color-picker-line'>
                                                                            <div className='color-picker-wrapper' onClick={() => this.setState({ CPEventRegBtn: false })} />
                                                                            <SketchPicker
                                                                                color={colorSchemeEventRegistration && colorSchemeEventRegistration.btn ? colorSchemeEventRegistration.btn : defaultColor.eventRegistration.btn}
                                                                                onChange={(color) => {
                                                                                    this.setState(prevState => ({
                                                                                        colorSchemeEventRegistration: {
                                                                                            ...prevState.colorSchemeEventRegistration,
                                                                                            btn: color.rgb
                                                                                        }
                                                                                    }));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="branding-template--section branding-template--fill">
                                                        <div className='select-dual-wrapper  box-content'>
                                                            <p className='wrapp-p'>{t('supplementary_picture')}</p>
                                                            <div
                                                                onClick={() => this.handleClickAddImage('wide-supplementary-picture')}
                                                                className={classNames('select-add-text', { 'disabled': addWideSupplementaryPictureDisabled || processing })}
                                                            >
                                                                {wideSupplementaryPicture ?
                                                                    <span className='branding-line'>
                                                                        <span
                                                                            className='branding-img'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({
                                                                                    wideSupplementaryPicture: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={wideSupplementaryPicture}
                                                                                className='event-bg'
                                                                                alt='img'
                                                                                loading='lazy'
                                                                            />
                                                                            <span className='rm-image'>
                                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                                            </span>
                                                                        </span>
                                                                        <p>{t('replace_wide_supplementary_picture')}  <span className='size'>{t('size_4')}</span></p>
                                                                    </span>
                                                                    :
                                                                    <span className='branding-line'>
                                                                        <span className='upload-img'>
                                                                            <img src={uploadIcon} alt='download' loading='lazy' />
                                                                            <p>{t('wide_supplementary_picture_upload')} <span className='size'>{t('size_4')}</span></p>
                                                                        </span>
                                                                    </span>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='add-file'
                                                                    ref={this.addWideSupplementaryPictureRef}
                                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                                    onChange={(event) => this.handleSetCropImage(event, 'wide-supplementary-picture')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='select-dual-wrapper  box-content'>
                                                            <div
                                                                onClick={() => this.handleClickAddImage('narrow-supplementary-picture')}
                                                                className={classNames('select-add-text', { 'disabled': addNarrowSupplementaryPictureDisabled || processing })}
                                                            >
                                                                {narrowSupplementaryPicture ?
                                                                    <span className='branding-line'>
                                                                        <span
                                                                            className='branding-img'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({
                                                                                    narrowSupplementaryPicture: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={narrowSupplementaryPicture}
                                                                                className='event-narrow'
                                                                                alt='img'
                                                                                loading='lazy'
                                                                            />
                                                                            <span className='rm-image'>
                                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                                            </span>
                                                                        </span>
                                                                        <p>{t('replace_narrow_supplementary_picture')} <span className='size'>{t('size_2')}</span></p>
                                                                    </span>
                                                                    :
                                                                    <span className='branding-line'>
                                                                        <span className='upload-img'>
                                                                            <img src={uploadIcon} alt='download' loading='lazy' />
                                                                            <p>{t('narrow_supplementary_picture_upload')} <span className='size'>{t('size_2')}</span></p>
                                                                        </span>
                                                                    </span>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='add-file'
                                                                    ref={this.addNarrowSupplementaryPictureRef}
                                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                                    onChange={(event) => this.handleSetCropImage(event, 'narrow-supplementary-picture')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="branding-template--section branding-template--fill">
                                                        <div className='select-dual-wrapper box-content'>
                                                            <p className='wrapp-p'>{t('footer_banner')}</p>
                                                            <div
                                                                onClick={() => this.handleClickAddImage('wide-footer-banner')}
                                                                className={classNames('select-add-text', { 'disabled': addWideFooterBannerDisabled || processing })}
                                                            >
                                                                {wideFooterBanner ?
                                                                    <span className='branding-line'>
                                                                        <span
                                                                            className='branding-img'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({
                                                                                    wideFooterBanner: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={wideFooterBanner}
                                                                                className='event-wide'
                                                                                alt='img'
                                                                                loading='lazy'
                                                                            />
                                                                            <span className='rm-image'>
                                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                                            </span>
                                                                        </span>
                                                                        <p>{t('replace_wide_footer_banner')} <span className='size'>{t('size_1')}</span></p>
                                                                    </span>
                                                                    :
                                                                    <span className='branding-line'>
                                                                        <span className='upload-img'>
                                                                            <img src={uploadIcon} alt='download' loading='lazy' />
                                                                            <p>{t('wide_footer_banner_upload')} <span className='size'>{t('size_1')}</span></p>
                                                                        </span>
                                                                    </span>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='add-file'
                                                                    ref={this.addWideFooterBannerRef}
                                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                                    onChange={(event) => this.handleSetCropImage(event, 'wide-footer-banner')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='select-dual-wrapper box-content'>
                                                            <div
                                                                onClick={() => this.handleClickAddImage('narrow-footer-banner')}
                                                                className={classNames('select-add-text', { 'disabled': addNarrowFooterBannerDisabled || processing })}
                                                            >
                                                                {narrowFooterBanner ?
                                                                    <span className='branding-line'>
                                                                        <span
                                                                            className='branding-img'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({
                                                                                    narrowFooterBanner: null
                                                                                })
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={narrowFooterBanner}
                                                                                className='event-narrow'
                                                                                alt='img'
                                                                                loading='lazy'
                                                                            />
                                                                            <span className='rm-image'>
                                                                                <img src={deleteIcon} loading='lazy' alt='X' />
                                                                            </span>
                                                                        </span>
                                                                        <p>{t('replace_narrow_footer_banner')} <span className='size'>{t('size_2')}</span></p>
                                                                    </span>
                                                                    :
                                                                    <span className='branding-line'>
                                                                        <span className='upload-img'>
                                                                            <img src={uploadIcon} alt='download' loading='lazy' />
                                                                            <p>{t('narrow_footer_banner_upload')} <span className='size'>{t('size_2')}</span></p>
                                                                        </span>
                                                                    </span>
                                                                }
                                                                <input
                                                                    type="file"
                                                                    className='add-file'
                                                                    ref={this.addNarrowFooterBannerRef}
                                                                    accept="image/png,image/jpeg,image/svg+xml"
                                                                    onChange={(event) => this.handleSetCropImage(event, 'narrow-footer-banner')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="branding-template--footer">
                                                        <span>STRMZ.IO Footer</span>
                                                    </div>
                                                </div>
                                                <div className='branding-template__text-wrapper branding-template__title-box'>
                                                    <span>{t('background_audience_lobby')}</span>
                                                    <div className="branding-template__h-box">
                                                        <button className="btn-icon" onClick={() => this.setState({ preview: 'lobby' })}>
                                                            <img src={previewIcon} alt='preview' loading='lazy' width="24px" height="24px" />
                                                        </button>
                                                        <span> {t('preview_al')}</span>
                                                    </div>
                                                </div>
                                                <div className="branding-template">
                                                    <div className="branding-template__screen branding-template--fill">


                                                        <button className='btn-icon' onClick={() => this.handleClickAddImage('background-lobby-audience')}>
                                                            <img src={uploadIcon} alt='download' loading='lazy' width="24px" height="24px" />
                                                        </button>
                                                        <span>{t('background_audience_lobby_upload')} <span className='size'>{t('size_4')}</span></span>
                                                        {backgroundLobbyAudience ? <button className='btn-icon btn-delete' onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.setState({
                                                                backgroundLobbyAudience: null
                                                            })
                                                        }}> <img src={deleteIcon} loading='lazy' alt='X' width="24px" height="24px" />
                                                        </button> : null}
                                                        <img
                                                            src={backgroundLobbyAudience ? backgroundLobbyAudience : defaultBackgroundLobby}
                                                            className='event-bg'
                                                            alt='img'
                                                            loading='lazy'
                                                        />
                                                        {addBgDisabled || processing ?
                                                            <Spinner animation="border" role="status" className='spinner' style={{ color: "#11B0ED" }}>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner> : null}

                                                        <div
                                                            onClick={() => this.handleClickAddImage('background-lobby-audience')}
                                                            className={classNames('select-add-text', { 'disabled': addBgDisabled || processing })}
                                                        >

                                                            <input
                                                                type="file"
                                                                className='add-file'
                                                                ref={this.addBgRef}
                                                                accept="image/png,image/jpeg,image/svg+xml"
                                                                onChange={(event) => this.handleSetCropImage(event, 'background-lobby-audience')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="branding-template__controls">
                                                        <div className="branding-template__circle"></div>
                                                        <div className="branding-template__circle"></div>
                                                        <div className="branding-template__circle"></div>
                                                        <div className="branding-template__circle"></div>
                                                        <div className="branding-template__circle"></div>
                                                        <div className="branding-template__circle"></div>
                                                    </div>
                                                </div>



                                                <div className='section-flex margin-top'>


                                                </div>
                                                <div className='buttons'>
                                                    <button
                                                        className='btn btn-save'
                                                        onClick={() => {
                                                            this.setState({ tab: 4 })
                                                            this.setState({ activeStep: 'time_and_date' })
                                                            if (!activeTab.includes('branding')) {
                                                                this.setState({ activeTab: [...this.state.activeTab, 'branding'] })
                                                            }
                                                        }}
                                                        disabled={processing}
                                                    >
                                                        {t('next')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                    </Tab> : null}
                                <Tab className="btn-arrow" eventKey="time_and_date" title="Date & Time" disabled={activeTab.length >= 3 && !activeTab.includes('summary') === true ? false : true}>
                                    <div className='scheduler-item'>
                                        <div className='item-body'>

                                            <div className='section-flex'>
                                                <div className='section-left'>
                                                    <div className='input-wrapper time-zone'>
                                                        <p className='wrapp-p'>{t('time_zone')}</p>
                                                        <select
                                                            value={timeZone}
                                                            onChange={(e) => this.setState({ timeZone: e.target.value })}
                                                            disabled={processing}
                                                        >
                                                            {moment.tz.names().map((item) => {
                                                                return (
                                                                    <option key={item} style={{ background: '#27272e' }} value={item}>{item}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className='input-flex'>
                                                        <div className='input-wrapper'>
                                                            <p className='wrapp-p'>{t('date')}</p>
                                                            <DateTime
                                                                inputProps={{
                                                                    placeholder: t('start_date'),
                                                                    className: 'date-input',
                                                                    disabled: processing,
                                                                    readOnly: true
                                                                }}
                                                                timeFormat={false}
                                                                dateFormat="L"
                                                                initialViewMode='days'
                                                                value={startDate}
                                                                isValidDate={this.validDate}
                                                                onChange={this.handleChangeStartDate}
                                                                displayTimeZone={timeZone || ''}
                                                                closeOnSelect={true}
                                                            />
                                                        </div>
                                                        <div className='input-wrapper'>
                                                            <p className='wrapp-p current-time'>
                                                                {t('time')}
                                                                {user && user.administrator ?
                                                                    <button
                                                                        className='btn btn-current-time'
                                                                        onClick={() => this.setState({ startDate: this.parseTime(moment().toDate().getTime(), timeZone) })}
                                                                    >
                                                                        {t('current_time')}
                                                                    </button>
                                                                    : null
                                                                }
                                                            </p>
                                                            <DateTime
                                                                inputProps={{
                                                                    placeholder: t('start_time'),
                                                                    className: 'time-input',
                                                                    disabled: processing,
                                                                    readOnly: true
                                                                }}
                                                                viewMode="time"
                                                                dateFormat={false}
                                                                value={startDate}
                                                                isValidDate={this.validDate}
                                                                onChange={this.handleChangeStartDate}
                                                                displayTimeZone={timeZone || ''}
                                                                timeConstraints={{ minutes: { step: 5 } }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='buttons'>
                                                <button
                                                    className='btn btn-save'
                                                    onClick={() => {
                                                        this.setState({ tab: this.isBranding() ? 5 : 4 })
                                                        this.setState({ activeStep: 'summary' })
                                                        if (!activeTab.includes('time_and_date')) {
                                                            this.setState({ activeTab: [...this.state.activeTab, 'time_and_date'] })
                                                        }
                                                    }}
                                                    disabled={processing}
                                                >
                                                    {t('next')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </Tab>
                                <Tab className="btn-arrow" eventKey="summary" title="Summary" disabled={activeTab.length >= 4 && !activeTab.includes('summary') === true ? false : true}>
                                    <div className='scheduler-item'>
                                        <div className='item-body'>
                                            <div className='section-flex'>
                                                <div className='section-left'>
                                                    <div className='line-details'>
                                                        <p>{t('event_title')}:</p>
                                                        <p className='title'>{title}</p>
                                                    </div>
                                                    {eventSubtitle ?
                                                        <div className='line-details'>
                                                            <p>{t('event_subtitle')}:</p>
                                                            <p className='white'>{eventSubtitle}</p>
                                                        </div>
                                                        : null}
                                                    <div className='line-details'>
                                                        <p>{t('participants')}:</p>
                                                        <p className='white'>{`< ${getTextParticipant(participants)}`}</p>
                                                    </div>
                                                    <div className='line-details'>
                                                        <p>{t('duration')}:</p>
                                                        <p className='white'>{`< ${getTextDuration(duration, t('hour'), t('hours'))}`}</p>
                                                    </div>
                                                    <div className='line-details'>
                                                        <p>{t('date')}:</p>
                                                        <p className='white'>{moment(new Date(startDate ? startDate.toDate().getTime() : moment().toDate().getTime())).format('L')}</p>
                                                    </div>
                                                    <div className='line-details'>
                                                        <p>{t('time')}:</p>
                                                        <p className='white'>{moment(new Date(startDate ? startDate.toDate().getTime() : moment().toDate().getTime())).format('HH:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}</p>
                                                    </div>
                                                </div>
                                                <div className='section-right'>
                                                    {hosts && hosts.length ?
                                                        <div className='input-wrapper'>
                                                            <p className='opacity-75'>{t('host_s')}:</p>
                                                            <div className='hosts-list'>

                                                                {hosts.map((host, index) => {
                                                                    if (!index) {
                                                                        return (
                                                                            <p key={index}>{host.username || host.mail || host.email}</p>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <p key={index}>, {host.username || host.mail || host.email}</p>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {guestSpeakers && guestSpeakers.length ?
                                                        <div className='input-wrapper'>
                                                            <p className='opacity-75'>{t('guest_speaker_s')}:</p>
                                                            <div className='hosts-list'>
                                                                {guestSpeakers.map((guestSpeaker, index) => {
                                                                    if (!index) {
                                                                        return (
                                                                            <p key={index}>{guestSpeaker.username || guestSpeaker.mail || guestSpeaker.email}</p>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <p key={index}>, {guestSpeaker.username || guestSpeaker.mail || guestSpeaker.email}</p>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                        : null}
                                                    {moderators && moderators.length ?
                                                        <div className='input-wrapper'>
                                                            <p className='opacity-75'>{t('moderator_s')}:</p>
                                                            <div className='hosts-list'>
                                                                {moderators.map((moderator, index) => {
                                                                    if (!index) {
                                                                        return (
                                                                            <p key={index}>{moderator.username || moderator.mail || moderator.email}</p>
                                                                        );
                                                                    } else {
                                                                        return (
                                                                            <p key={index}>, {moderator.username || moderator.mail || moderator.email}</p>
                                                                        );
                                                                    }
                                                                })}
                                                            </div>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                            {user.subscription && participants && user.subscription.audience_size && participants > user.subscription.audience_size ?
                                                <div className='input-wrapper'>
                                                    <p className='opacity-75'>{t('audience_overage_quote')}:</p>
                                                    <p className="no-wrapp">
                                                        {t('ap_1')}
                                                        ${((user.subscription && user.subscription.attendee_overage_usd ? user.subscription.attendee_overage_usd : 1) * (participants - user.subscription.audience_size) * duration)}
                                                        {t('ap_2')}
                                                    </p>
                                                </div>
                                                : null}
                                            {showOverage && user.subscription && user.subscription.type && user.subscription.type === 'explorer' && duration && duration > this.getHoursBalance() ?
                                                <div className='input-wrapper'>
                                                    <p className='opacity-75'>{t('duration_overage_quote')}:</p>
                                                    <p className="no-wrapp">
                                                        {t('ap_3')}
                                                        ${400 * (duration - this.getHoursBalance())}
                                                        {t('ap_4')}
                                                    </p>
                                                </div>
                                                : null}
                                            <div className='buttons'>
                                                {showOverage && user.subscription && user.subscription.type && user.subscription.type === 'pro' && duration && duration > this.getHoursBalance() ?
                                                    <Link
                                                        to="/my-subscription"
                                                        className='btn btn-save'
                                                    >
                                                        {t('manage_my_subscription')}
                                                    </Link>
                                                    :
                                                    <button
                                                        className='btn btn-save'
                                                        onClick={() => {
                                                            this.submitForm()
                                                            this.setState({ activeStep: 'share' })
                                                            if (!activeTab.includes('summary')) {
                                                                this.setState({ activeTab: [...this.state.activeTab, 'summary'] })
                                                            }
                                                        }}
                                                        disabled={processing}
                                                    >
                                                        {this.checkAcceptCharge() ? t('accept_charge') : !postId ? t('create_event') : t('update_event')}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab className="btn-arrow" eventKey="share" title="Share" disabled={activeTab.length >= 5 && !activeTab.includes('summary') === true ? false : true}>
                                    <div className='scheduler-item'>
                                        <div className='item-body'>
                                            <div className='section-flex'>
                                                <div className='section-left'>

                                                    {!eventId ? <div className='spinner-container'><Spinner animation="border" role="status" className='spinner' style={{ color: "#11B0ED" }}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner></div> : null}
                                                    {eventId ?
                                                        <div className='btn-copy-wrapper'>
                                                            <button
                                                                className='btn btn-copy'
                                                                onClick={() => this.handleCopy(eventItem, 'admin', this.firstTooltip)}
                                                                data-for="event-copy-presenter"
                                                                data-tip={t('link_copied')}
                                                                ref={ref => this.firstTooltip = ref}
                                                                disabled={processing}
                                                            >
                                                                <span>{t('copy_host_link')}<br /><label>{t('copy_host_link_prefix')}</label></span>
                                                                <img src={copyIcon} alt="copy" />
                                                            </button>
                                                            <ReactTooltip
                                                                id="event-copy-presenter"
                                                                isCapture={true}
                                                                className=""
                                                                place="top"
                                                                type="dark"
                                                                effect="solid"
                                                                event="none"
                                                                eventOff="click"
                                                                delayHide={2000}
                                                            />
                                                        </div>
                                                        : null
                                                    }
                                                    {eventId ?
                                                        <div className='btn-copy-wrapper'>
                                                            <button
                                                                className='btn btn-copy'
                                                                onClick={() => this.handleCopy(eventItem, 'audience', this.secondTooltip)}
                                                                data-for="event-copy-audience"
                                                                data-tip={t('link_copied')}
                                                                ref={ref => this.secondTooltip = ref}
                                                                disabled={processing}
                                                            >
                                                                <span>{t('copy_audience_link')}</span>
                                                                <img src={copyIcon} alt="copy" />
                                                            </button>
                                                            <ReactTooltip
                                                                id="event-copy-audience"
                                                                isCapture={true}
                                                                className=""
                                                                place="bottom"
                                                                type="dark"
                                                                effect="solid"
                                                                event="none"
                                                                eventOff="click"
                                                                delayHide={2000}
                                                            />
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                                <div className='section-right'>
                                                    <div className='line-details'>
                                                        <p>{t('event_title')}:</p>
                                                        <p className='title'>{title}</p>
                                                    </div>
                                                    {eventSubtitle ?
                                                        <div className='line-details'>
                                                            <p>{t('event_subtitle')}:</p>
                                                            <p className='white'>{eventSubtitle}</p>
                                                        </div>
                                                        : null}
                                                    <div className='line-details'>
                                                        <p>{t('participants')}:</p>
                                                        <p className='white'>{`< ${getTextParticipant(participants)}`}</p>
                                                    </div>
                                                    <div className='line-details'>
                                                        <p>{t('duration')}:</p>
                                                        <p className='white'>{`< ${getTextDuration(duration, t('hour'), t('hours'))}`}</p>
                                                    </div>
                                                    <div className='line-details'>
                                                        <p>{t('date')}:</p>
                                                        <p className='white'>{moment(new Date(startDate ? startDate.toDate().getTime() : moment().toDate().getTime())).format('L')}</p>
                                                    </div>
                                                    <div className='line-details'>
                                                        <p>{t('time')}:</p>
                                                        <p className='white'>{moment(new Date(startDate ? startDate.toDate().getTime() : moment().toDate().getTime())).format('HH:mm a')}, {moment.tz(momentZone.tz.guess()).zoneAbbr()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='buttons'>
                                                {eventItem && config && config.build && eventItem.presenterLink ?
                                                    <a
                                                        href={eventItem.presenterLink}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <button
                                                            className='btn btn-save'
                                                            disabled={processing}
                                                        >
                                                            {t('view_event_page')}
                                                        </button>
                                                    </a>
                                                    : null
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                    :
                    <Loader
                        text={t('Loading')}
                        dots
                    />
                }
                {hostsOverlay ?
                    <div className='my-hosts-overlay-wrapper'>
                        <ClickOutside
                            clickOutside={this.closeHostOverlay}
                            className='my-hosts-overlay'
                        >
                            <MyHosts
                                overlay
                                closeOverlay={this.closeHostOverlay}
                            />
                        </ClickOutside>
                    </div>
                    : null
                }
                {cropView ?
                    <div className="crop-container">
                        <Cropper
                            image={imgUrl}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            onCropChange={(crop) => this.setState({ crop: crop })}
                            onCropComplete={(croppedArea, croppedAreaPixels) => this.setState({ croppedAreaPixels: croppedAreaPixels })}
                            onZoomChange={(zoom) => this.setState({ zoom: zoom })}
                        />
                        <div className='crop-buttons'>
                            <button
                                onClick={this.cancelCrop}
                                className='btn btn-crop-close'
                            >
                                {t('cancel')}
                            </button>
                            <button
                                onClick={this.saveCropImage}
                                className='btn btn-crop-done'
                            >
                                {t('crop')}
                            </button>
                        </div>
                    </div>
                    : null}
                {preview ?
                    <Preview
                        {...this.state}
                        closePreview={() => this.setState({ preview: '' })}
                    />
                    : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        events: state.firebase.events,
        defaults: state.firebase.defaults,
        userLoaded: state.firebase.userLoaded
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateEvents: (value) => {
            dispatch(firebaseActions.updateEvents({ events: value }));
        },
        showSignIn: (value) => {
            dispatch(appActions.showSignIn({ signIn: value, signUp: false }));
        }
    };
};

const EventSchedulerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventScheduler);

export default withTranslation()(withRouter(EventSchedulerContainer));